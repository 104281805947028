<template>
  <div
    class="fixed z-4 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !visible,
    }"
  >
    <div class="p-2 border-1 rounded-lg w-3/4 h-1/4">
      <div class="h-3/5">
        <div class="p-4 bg-white rounded-lg w-full">
          <div class="flex gap-2 justify-center mt-2">
            <div>
              <h3 class="font-bold">Conflicted Speakers</h3>
              <div class="font-normal text-sm py-4">
                Speaker Name:
                <span class="font-semibold" v-if="speaker">{{
                  speaker.speaker_name
                }}</span>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div class="grid grid-cols-1 justify-items-center">
              <div class="items-start">
                <div class="grid grid-rows-1 items-center gap-3 mt-2">
                  <div class="flex item-center overflow-x-auto">
                    <table
                      class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                    >
                      <thead
                        class="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                      >
                        <tr>
                          <th scope="col" class="px-6 py-3">Deal Name</th>
                          <th scope="col" class="px-6 py-3">Status</th>
                          <th scope="col" class="px-6 py-3">
                            Event Start Date
                          </th>
                          <th scope="col" class="px-6 py-3">Event End Date</th>
                          <th scope="col" class="px-6 py-3">Unavailable</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="item in conflictSpeakers" :key="item.id">
                          <td class="text-center px-6 py-4">
                            {{ item.deal_name }}
                          </td>
                          <td class="text-center px-6 py-4">
                            {{ item.status }}
                          </td>
                          <td class="text-center px-6 py-4">
                            {{ item.event_start_date }}
                          </td>
                          <td class="text-center px-6 py-4">
                            {{ item.event_end_date }}
                          </td>
                          <td class="text-center px-6 py-4">
                            <span v-if="item.unavailable">
                              &#10004;
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 flex justify-center gap-4" id="footer">
            <button
              class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
              @click="showDealConflicts()"
              v-show="speaker && speaker.availability == 1 && speaker.sp_exist == 1">
              Show Deal Conflicts
            </button>
            <button
              class="px-4 py-1 ml-2 text-gray-700 bg-white ring-1 ring-inset ring-gray-700 rounded-md"
              @click="closeAll()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  showDealConflicts,
} from "@/services/ShortlistedSpeakerService";
//import AppInput from "@/components/AppInput";
export default {
  name: "pages.shortlisted-speakers.ShowConflictSpeakers",
  components: {
    //AppLabel,
    //AppInput,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    dealInfo: {
      type: Object,
      required: true,
    },
    conflictSpeakerList: {
      type: Object,
      required: true,
    },
    speaker: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    
    conflictSpeakers: [],
    dataLoaded: false
  
  }),
  updated() {

    if (this.conflictSpeakerList && this.dataLoaded == false)
    {
      this.conflictSpeakers = this.conflictSpeakerList;
      this.dataLoaded = true;
    } 
  },
  methods: {
    showDealConflicts(){
      this.conflictSpeakers = [];
      let data = {
        deal: this.dealInfo,
        speaker: this.speaker,
      };
      showDealConflicts(data)
        .then((res) => {

          this.conflictSpeakers = res.data;
        })
        .catch((err) => console.log(err));
    },
    closeConflictModal() {
      this.dataLoaded = false;
      this.conflictSpeakers = [];
      this.$emit("update:visible", false);
    },
    closeAll() {
      this.closeConflictModal();
      this.$emit("update:isVisible", false);
      setTimeout(() => {
        this.$emit("update:isVisible", true);
      }, 500);
    },
  },
};
</script>

<style scoped></style>
