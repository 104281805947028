<template>
    <div class="flex flex-col justify-center items-center min-h-screen bg-gray-100 py-12 sm:px-6 lg:px-8">
        <div class="py-12"><h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Proposal Email Settings</h2></div> 
        <div class="mt-12 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div class="font-bold text-sm py-4">
                    <div class="flex justify-between ...">
                        <div >User Name: </div>
                        <div>
                            <span class="font-semibold"> {{ loginUserName }}</span>
                        </div>
                    </div>
                </div>
                <div class="font-bold text-sm py-4">
                    <div class="flex justify-between ...">
                        <div class="py-2">SMTP Email:</div>
                        <div>
                            <span class="font-normal"> 
                    <jet-input type="email"  id="smtp_username" class="appearance-none block w-45 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400
                    focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value='' required/></span>
                        </div>
                    
                    </div>
                </div>
                <div class="font-bold text-sm py-4">
                    <div class="flex justify-between gap-4">
                        <div class="py-2">SMTP Password:</div>
                        <div>
                            <span class="font-normal"> 
                    
                    <jet-input type="password" id="smtp_password" class="appearance-none block w-45 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400
                    focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" value='' required/></span>
                        </div>
                    
                    </div>
                </div>
                <div class="py-8">
                    <button  @click="saveSettings" class="bg-transparent hover:bg-rose-500 text-rose-700 font-semibold hover:text-white py-2 px-4 border border-rose-500 hover:border-transparent rounded">Save Settings</button>
                </div>
                <div>
                    <div v-show="foundError"> <div class="flex items-center text-rose-500 mt-2 mb-4 ml-2" style="white-space: pre;"><strong>{{ errorMessage }}</strong></div></div>
                </div>
            </div>
        </div>
       
    </div>
    <div v-if="showLoadingScreen" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-slate-300">
        <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
            {{ loadingText }}
        </loading>
    </div>

</template>

<script>
    import Loading from "@/components/Loading";
    import JetInput from "@/components/JetInput";
    import {   saveEmailSettings  ,getSavedEmailSettings } from "@/services/ShortlistedSpeakerService";
    import { ElMessage} from 'element-plus';
    export default {
    name: "pages.proposal.proposalemailsettings",
    components: {
     
        JetInput,
        Loading
    },
    data: () => ({
        loadingText: 'Loading...',
        loginUserId: '',
        loginUserName: '',
        showLoadingScreen: false,
        foundError: false,
        errorMessage: ''

    }),
    async mounted() {
       await this.init();
    },
    /* global ZOHO */
    methods:{
        async init() {
            this.showLoadingScreen = true;
            ZOHO.embeddedApp.on("PageLoad", async (data) => {

               const user_res =  await ZOHO.CRM.CONFIG.getCurrentUser();
                if (user_res)
                {
                    const currentUserDetails = user_res.users[0];
                    this.loginUserId = currentUserDetails.id;
                    this.loginUserName = currentUserDetails.full_name;
                    console.log(currentUserDetails.id);
                     const getEmailCred = await getSavedEmailSettings(currentUserDetails.id);
                     if (getEmailCred.data)
                     {      
                        const smtp_username = document.querySelector("#smtp_username");
                        const smtp_password = document.querySelector("#smtp_password");
                        smtp_username.value = getEmailCred.data.smtp_username;
                        smtp_password.value = getEmailCred.data.smtp_password;
                     }
                }          
             });
            ZOHO.embeddedApp.init();
            setTimeout(() => {
                this.showLoadingScreen = false; 
            }, 1500);
           
        },
        saveSettings()
        {
            const smtp_username = document.querySelector("#smtp_username");
            const smtp_password = document.querySelector("#smtp_password");
            if (smtp_username.value == '')
            {
                this.errorMessage = 'SMTP Email can not be empty.';
                this.foundError = true;
                return false;
            } else if (smtp_password.value == '' ) {
                this.errorMessage = 'SMTP Password can not be empty.';
                this.foundError = true;
                return false;
            }
            this.errorMessage = '';
            this.foundError = false;
            const data = {
                'smtp_username': smtp_username.value,
                'smtp_password': smtp_password.value,
                'loginUserName': this.loginUserName,
                'loginUserId': this.loginUserId
            }
            this.generateProposal(data);
        },
        async generateProposal(arg)
        {
            const proposalResponse = await saveEmailSettings(arg);
            if (proposalResponse && proposalResponse.data.status == "success")
            {
                ElMessage({
                type: 'success',
                message: proposalResponse.data.message,
                })
            } else
            {
                ElMessage({
                type: 'info',
                message: proposalResponse.data.message,
                })
            }
            
        },
    }
    };
</script>