import {createRouter, createWebHistory} from "vue-router";
import Widget from "@/views/Shortlisted-Speakers/Widget.vue";
import Calenders from "@/views/Calendar/Calenders";
import SpeakerCalenders from "@/views/SpeakerCalendar/SpeakerCalenders";
import UploadImage from "@/views/Upload/UploadImage";
import Proposal from "@/views/Proposal/GenerateProposal";
import ProposalEmailSettings from "@/views/Proposal/ProposalEmailSettings";
const routes = [
    {
        path: "/shortlisted-speakers",
        name: "shortlisted.speakers",
        component: Widget
    },
    {
        path: "/calendar",
        name: "calender.calendars",
        component: Calenders
    },
    {
        path: "/speaker-calendar",
        name: "calender.speakerCalendars",
        component: SpeakerCalenders
    },
    {
        path: "/upload-image",
        name: "upload.uploadImage",
        component: UploadImage
    },
    {
        path: "/generate-proposal",
        name: "proposal.generateProposal",
        component: Proposal
    },
    {
        path: "/proposal-email-settings",
        name: "proposal.ProposalEmailSettings",
        component: ProposalEmailSettings
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;