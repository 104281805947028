<template>
    <div class="flex items-center">
        <input
            type="checkbox"
            :class="`h-5 w-5 ${bgColorClass} ${fgColorClass}`"
            :checked="checked"
            @change="$emit('update:checked', $event.target.checked)"
            :disabled="disabled"
            :id="uniqueId"
        />
        <label :for="uniqueId" class="ml-3 text-sm font-medium text-gray-700">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'components.appCheckbox',
    props: {
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        bgColorClass: {
            type: String,
            required: false,
            default: 'bg-white',
        },
        fgColorClass: {
            type: String,
            required: false,
            default: 'text-gray-700',
        },
    },
    data() {
        return {
            uniqueId: `appCheckbox-${Math.random().toString(36).substring(2, 9)}`,
        };
    }
}
</script>
