import axios from "axios";

var backendApi = process.env.VUE_APP_BACKEND_API_URL;

export async function filterSpeakers(param) {
    return await axios.get(`${backendApi}/api/speakers/filter`, {params: param});
}

export async function fetchShortlistedSpeakers(dealId) {
    return await axios.get(`${backendApi}/api/shortlisted-speakers/${dealId}`);
}

export async function syncShortlistedSpeaker(dealId) {
    return await axios.get(`${backendApi}/api/zoho/sync-shorlisted-speakers/${dealId}`);
}

export async function occupySpeakers(data) {
    return await axios.post(`${backendApi}/api/speakers/occupy-speaker`, data);
}

export async function noAssignSpeaker(data) {
    return await axios.post(`${backendApi}/api/speakers/unassign-speaker`, data);
}

export async function fetchConflictData(data) {
    return await axios.post(`${backendApi}/api/speakers/fetch-conflict-speakers`, data);
}

export async function showDealConflicts(data) {
    return await axios.post(`${backendApi}/api/speakers/show-deal-conflicts`,data);
}

export async function updateShortlistedSpeaker(speakerId, data) {
    return await axios.put(`${backendApi}/api/shortlisted-speakers/${speakerId}/update`, data);
}

export async function generateFile(data) {
    return await axios.post(`${backendApi}/api/shortlisted-speakers/generate-file`, data)
}

export async function generateInvoice(data) {
    return await axios.post(`${backendApi}/api/shortlisted-speakers/generate-invoice`, data)
}

export async function generateReqProposal(data) {
    return await axios.post(`${backendApi}/api/proposal/generate-proposal`, data)
}

export async function sendEmailToClient(data) {
    return await axios.post(`${backendApi}/api/proposal/send-proposal`, data)
}

export async function saveEmailSettings(data) {
    return await axios.post(`${backendApi}/api/proposal/save-email-settings`, data)
}

export async function getSavedEmailSettings(userId) {
    return await axios.get(`${backendApi}/api/proposal/get-email-settings/${userId}`)
}
