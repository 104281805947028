<template>
    <div
        class="fixed z-4 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full	 bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >
        <div class="p-2 border-1  rounded-lg w-3/4 h-1/4 ">

            <div class="h-3/5	">
                <div class="p-4 bg-white rounded-lg w-full ">
                    <div class="flex gap-2 justify-center mt-2">
                        <div>
                            <h3 class="font-bold">Generate Invoice</h3>
                            <div class="font-normal text-sm">Client Name: <span class="font-semibold">{{ dealInfo.client_name }}</span></div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="grid grid-cols-1 justify-items-center">
                            <div class="items-start">
                                <div class="grid grid-rows-1 items-center gap-3 px-4 py-2">
                                    <app-label>How many invoices do you want to generate?</app-label>
                                    <div class="flex item-center">
                                        
                                        <app-select :padding-class="`py-1 px-1 text-xs`" placeholder="Generate Invoices" :options="invoiceChunck"
                                            @optionPicked="optionPicked"
                                            v-model:selectedOption="selectedNumber">
                                        </app-select>
                                    </div>
                                </div>
                                <div class="grid grid-rows-1 items-center gap-3 mt-2">
                                    <label class="text-sm text-right font-semibold">Total Bill:{{ calculatedBill }}</label>
                                    <div class="flex item-center">
                                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead class="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        No
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Percentage(%)
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Amount
                                                    </th>
                                                    <th scope="col" class="px-4 py-3">
                                                        Due Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class=" text-center">
                                                <tr v-for="(item) in tableRows" :key="item.id">
                                                    <td> {{ item.ordinal }}</td>
                                                    <td>
                                                        <app-input :style="`px-1 py-2 text-xs`" @change="sumRowAmount" v-model:value="item.parcentage" placeholder="Percentage" />
                                                    </td>
                                                    <td>{{ item.amount }}</td>
                                                    <td><div class="mx-4 my-2"><date-time-input v-model="item.date" :enable-time-picker="false" teleport-center format="dd/MM/yyyy"></date-time-input></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="flex item-center">
                                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Admin Fee
                                                    </td>
                                                    <td>
                                                        <app-input :style="`px-1 py-2 text-xs`" v-model:value="adminFee" placeholder="Admin Fee"  @keyup="adFeeAdjustment" />
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 flex justify-center" id="footer">
                        <button @click="generateInvoice"
                                :disabled='isDisabled' :class="['inline-flex items-center px-4 py-1 text-sm font-semibold text-white  shadow leading-6 rounded-md transition ease-in-out duration-150', isDisabled ? ' bg-gray-300' : ' bg-blue-500 hover:bg-blue-600 cursor-pointer']"
                        > Generate
                        </button>
                        <button class="px-4 py-1 ml-2 text-gray-700 bg-white ring-1 ring-inset ring-gray-700 rounded-md" @click="closeModal()">Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="showLoadingScreen" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen">
                <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
                    {{ loadingText }}
                </loading>
            </div>
        </div>

        <div
        class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
                'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,
            }"
        >
            <div class="px-10 py-5 bg-white rounded-lg ring-2 w-96">
                <div class="flex items-center text-blue-500 mt-2 mb-4 ml-2">{{ message }}</div>
                <div class="flex justify-center mt-2">
                    <button
                        @click="closeAll"
                        class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>


    </div>

</template>


<script>

import AppLabel from "@/components/AppLabel";
import AppInput from "@/components/AppInput";
import AppSelect from "@/components/AppSelect";
import DateTimeInput from "@/components/DateTimePicker.vue";
import Loading from "@/components/Loading";

export default {
    name: "pages.shortlisted-speakers.GenerateInvoice",
    components: {
        AppLabel,
        AppInput,
        AppSelect,
        DateTimeInput,
        Loading
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        invoiceLoaded: {
            type: Boolean,
            default: false,
        },
        dealInfo: {
            type: Object,
            required: true,
        },
        shortlistedSpeakerList: {
            type: Object,
            required: true,
        },
        shortListedSpeakersInfo: {
            type: Object,
            required: true,
        },
        calculatedBill: {
            type: Number,
            required: true,  
        },
    },
    data: () => ({
        totalInstallmentAmount: 0,
        adminFee: null,
        recordSynced: 0,
        isDisabled: true,
        ordinality: ["1st","2nd","3rd", "4th", "5th"],
        selectedNumber: { label: '2', id: 2 },
        generatedRow: 2,
        invoiceChunck: [{ label: '1', id: 1 }, { label: '2', id: 2 }, { label: '3', id: 3 }, { label: '4', id: 4 }, { label: '5', id: 5 }],
        showLoadingScreen: false,
        loadingText: 'Please Wait!',
        speakersData: [],
        invoiceItems: [],
        invoiceObj: {},
        speakerProductId: '',
        productTaxDetails: '',
        adminFeeProductId: '',
        showPopUpModal: false,
        message: '',
        ord_id:  process.env.VUE_APP_ORG_ID,
        tableRows: [
            {
                id: 1,
                ordinal: "1st",
                parcentage: null,
                amount: null,
                date: null,
            },
            {
                id: 2,
                ordinal: "2nd",
                amount: null,
                parcentage: null,
                date: null,
            },
        ],
    }),
    updated(){
        if (this.isVisible && this.invoiceLoaded != true){
            if (this.dealInfo.saxton_or_csnz == 'CSNZ')
            {
                this.adminFee = this.calculatedBill*.10;
            }
            if (this.dealInfo.saxton_or_csnz == 'Saxton')
            {
                this.adminFee =  499;
            }
            this.generateTableRows(this.dealInfo.function_date);
            this.$emit('update:invoiceLoaded', true);
        }
       
    },
    /* global ZOHO */
    methods: {  
        
        generateTableRows(function_date) {
            this.tableRows.forEach((ele, index) => {
                if (index == 0) {
                ele.date = this.adjustDays(new Date(), 7, "Add");
                } else {
                ele.date = this.adjustDays(function_date, 30, "Subtract");
                }
            });
        },
        optionPicked(option) {
            // if(option.id == 1) {
            //     return false
            // }
            if (option.id == this.generatedRow) {
                return false;
            }
            this.populateInvoiceRow(option.id)
            this.generatedRow = option.id;
        },
        adFeeAdjustment(event){
            if (event.target.value) {   
                this.adminFee = event.target.value;      
            } else {
                this.adminFee = null;
            }
        },
        populateInvoiceRow(optionId) {
            let loopLength = 0
            if (this.generatedRow < optionId) {
                loopLength = optionId - this.generatedRow;
                for (let index = 0; index < loopLength; index++) {
                    this.tableRows.push(
                        {
                            "id": this.tableRows.length,
                            "ordinal": this.ordinality[this.tableRows.length],
                            "parcentage": "",
                            "amount": "",
                            "date": this.adjustDays(this.dealInfo.function_date,30,'Subtract')
                        }
                    )
                }
            } else {
                loopLength = this.generatedRow - optionId;
                for (let index = 0; index < loopLength; index++) {
                    this.tableRows.pop();
                }
            }
            this.sumRowAmount();
        },
        sumRowAmount() {
            //console.log(this.calculatedBill);
            this.totalInstallmentAmount = 0
            for (let i = 0, len = this.tableRows.length; i < len; i++) {
                const item = this.tableRows[i];
                this.totalInstallmentAmount += item.parcentage == null ? 0 : parseFloat(item.parcentage)
                if (this.calculatedBill != 0 && item.parcentage != null) {
                    item.amount = (Math.round((this.calculatedBill / 100) * item.parcentage)).toFixed(2);
                }
                if(this.totalInstallmentAmount == 100) {
                    this.isDisabled = false
                } else {
                    this.isDisabled = true
                }
            }
        },
        showPopUpMessage(message){
            this.showLoadingScreen = false;
            this.showPopUpModal = true;
            this.message = message;
            return false;
        },
        async generateInvoice() {
            this.loadingText = 'Generating Invoice';
            this.showLoadingScreen = true;
            var adminFeeApvl = false;
            let invCount = 1;
            this.recordSynced = 0;
            const getProducts = await ZOHO.CRM.API.searchRecord({Entity:"Products",Type:"criteria",Query:"((Product_Code:equals:Admin Fee)or(Product_Code:equals:Speaker Fee))"});
            if (getProducts.data)
            {     
                getProducts.data.forEach(productDetails => {
                    if (productDetails.Product_Code == "Speaker Fee") 
                    {
                        this.speakerProductId = productDetails.id;
                        this.productTaxDetails = productDetails.Tax;
                    }
                    if (productDetails.Product_Code == "Admin Fee")
                    {
                        this.adminFeeProductId = productDetails.id;
                    }
                });
            } else {
                this.showPopUpMessage('No Speaker Fee/Admin Fee product found');
                return false;
            }

            if (this.speakerProductId == '')
            {
                this.showPopUpMessage('No Speaker Fee product Found where product code is "Speaker Fee"');
                return false;
            }

            if (this.adminFee != null && this.adminFeeProductId == '')
            {
                this.showPopUpMessage('No Admin Fee product Found where product code is "Admin Fee"');
                return false;
            }

            for (let i = 0, len = this.tableRows.length; i < len; i++) {
                adminFeeApvl = false;
                if (i == 0)
                    adminFeeApvl = true;    
                const item = this.tableRows[i];
                const due_date = item.date;
                this.invoiceItems = []           
                this.shortlistedSpeakerIds = []
                this.generateInvoiceData(item.parcentage,adminFeeApvl,due_date,invCount)
                this.sendInvoiceDataToAPI()
                invCount++;
            }
        },
        generateInvoiceData(parcentage,adminFeeApvl,due_date,invCount) {
            this.invoiceObj = {}         
            let lineItems = {}; 
            for (let index = 0; index < this.shortListedSpeakersInfo.length; index++) {
                const element = this.shortListedSpeakersInfo[index];
                let speakerBill = element.listprice;
                let speakerName = element.name; 
                this.invoiceObj['Subject'] = this.dealInfo.booking_number+' '+this.dealInfo.client_name+' '+ invCount;
                this.invoiceObj['Invoice_Date'] = new Date().toISOString().split('T')[0];
                // if (adminFeeApvl)
                // {
                //     due_date = this.adjustDays(due_date,7,'Add');
                // }else {
                //     due_date = this.adjustDays(function_date,30,'Subtract');
                // }
                
                this.invoiceObj['Due_Date'] = (due_date != null) ? new Date(due_date).toISOString().split('T')[0] : '';
                //this.invoiceObj['Due_Date'] = (invoice_date != null) ? new Date(invoice_date).toISOString().split('T')[0] : '';
                this.invoiceObj['Account_Name'] = this.dealInfo.acc_id;
                this.invoiceObj['Contact_Name'] = this.dealInfo.contactId;
                this.invoiceObj['Deal_Name__s'] = this.dealInfo.deal_id;
                this.invoiceObj['Currency'] = this.dealInfo.currency;
                this.invoiceObj['Status'] = 'Draft';
                this.invoiceObj['Invoice_Credit_Note'] = 'Invoice';

                if (this.dealInfo.saxton_or_csnz == 'CSNZ')
                {
                    this.invoiceObj['CSNZ_Consultants'] = this.dealInfo.consultant; 
                    this.invoiceObj['CSNZ_CSU'] = this.dealInfo.co_ordinator;  
                } else {
                    this.invoiceObj['Saxton_Consultants'] = this.dealInfo.consultant;
                    this.invoiceObj['Saxton_CSU'] = this.dealInfo.co_ordinator;
                }
                
                if (this.dealInfo.saxton_or_csnz ) {   
                    this.productTaxDetails.forEach(taxDetails => {
                        let taxInfo = taxDetails.split(" - ");
                        let taxCategory = taxInfo[0];
                        let taxPercent = taxInfo[1].replace(' %', '');
                        if (this.dealInfo.saxton_or_csnz == 'Saxton' && taxCategory == 'GST on Income') {
                            this.invoiceObj['Add_To_Saxton_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);
                        } else if (this.dealInfo.saxton_or_csnz == 'CSNZ' && taxCategory == 'GST on Income CSNZ') {
                            this.invoiceObj['Add_To_CSNZ_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);
                        }else if (this.dealInfo.saxton_or_csnz == 'Engage' && taxCategory == 'GST on Income') {
                            this.invoiceObj['Add_To_Saxton_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);                    
                        } else if (this.dealInfo.saxton_or_csnz == 'Voiceover' && taxCategory == 'GST on Income') {
                            this.invoiceObj['Add_To_Saxton_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);                    
                        } else if (this.dealInfo.saxton_or_csnz == 'Power Pets'  && taxCategory == 'GST on Income') {
                            this.invoiceObj['Add_To_Saxton_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);                  
                        } else if (this.dealInfo.saxton_or_csnz == 'Mixi Events' && taxCategory == 'GST on Income') {
                            this.invoiceObj['Add_To_Saxton_Xero'] = true;
                            lineItems['name'] = taxCategory;
                            lineItems['percentage'] = Number(taxPercent);
                        } 
                    
                    });
                    
                }         
               
               //Invoiced_Items
            
                this.invoiceItems.push(
                    {
                        "product": this.speakerProductId,
                        "product_description": this.dealInfo.booking_number+" \n"+speakerName,
                        "list_price": (speakerBill / 100) * parcentage,
                        "quantity": 1,
                        "Discount": 0,
                        "line_tax": [lineItems],
                        "total": (speakerBill / 100) * parcentage
                    }
                )
                this.invoiceObj['Product_Details'] = this.invoiceItems;
            }


            if (adminFeeApvl && this.adminFee != null) {
                //Invoiced_Items
                let admin_Fee = !isNaN(Number(this.adminFee)) ? Number(this.adminFee)	: 0;
                this.invoiceObj['Product_Details'].push(
                    {
                        "product": this.adminFeeProductId,
                        "product_description": this.dealInfo.booking_number,
                        "list_price": admin_Fee,
                        "quantity": 1,
                        "Discount": 0,
                        "line_tax": [lineItems],
                        "total":  admin_Fee
                    }
                )
            }
            
        },
        async sendInvoiceDataToAPI() {
        
            let invoiceData = JSON.parse(JSON.stringify(this.invoiceObj));
            console.log(invoiceData);
            try{
                const createRecordResult = await ZOHO.CRM.API.insertRecord({Entity:"Invoices",APIData:invoiceData,Trigger:["workflow"]});

                let data = createRecordResult.data[0];
                
                if (data.code == "SUCCESS") {
                    this.recordSynced = this.recordSynced + 1;
                }

                if(this.recordSynced == this.tableRows.length)
                {
                    
                    this.updateDealRecord();
                    this.updateShortlistedSpeaker();
                }

                
            }catch (error) {
                this.showPopUpMessage('Invoice not created.Error Detail: '+ error.data[0].message);
                console.log('error', error);
                return false;
            }

            
        },
        async updateDealRecord(){
            var update_param={
                Entity:"Deals",
                APIData:{
                    "id": this.dealInfo.deal_id.toString(),
                    "Invoice_Status": "Created"
                },
                Trigger:["workflow"]
            }
            const update_res =  await ZOHO.CRM.API.updateRecord(update_param);
            return update_res;
        },
        async updateShortlistedSpeaker(){

            var response = {'status' : 'failed' ,'message': 'failed to update record.'};

            let total_record = this.shortListedSpeakersInfo.length;
            let updatedRecord = 0;
            for await (const speakerDetails of this.shortListedSpeakersInfo) {
                var param = {
                    Entity:"Opportunities_X_Speakers",
                    APIData:{
                        "id": speakerDetails.ss_id,
                        "Invoice_Created": true
                    },
                    Trigger:["workflow"]
                }
               const update_res =  await ZOHO.CRM.API.updateRecord(param);
               //console.log('update_res',update_res);
               if (update_res.data && update_res.data[0].code == "SUCCESS")
               {
                  updatedRecord += 1;
               }

               if (updatedRecord == total_record)
               {
                    this.showLoadingScreen = false;
                    this.showPopUpModal = true;
                    this.message = 'Invoice Created Successfully!';
                    response['status'] = 'success';
                    response['message'] = 'Record Updated Successfully!';
                    this.closeAll();
               }
            }      
            return response;
        },
        closeModal() {
            this.$emit('update:isVisible', false)
        },
        closeAll(){
            setTimeout(() => {
                this.showPopUpModal = false;
                this.closeModal();    
                window.parent.location = document.referrer+"crm/"+this.ord_id+"/tab/Potentials/"+this.dealInfo.deal_id;
            }, 3000); 
        },
        adjustDays(date, days, operation) {
            var result = new Date(date);
            if (operation == 'Add')
            {
                result.setDate(result.getDate() + days);
            }

            if (operation == 'Subtract')
            {
                result.setDate(result.getDate() - days);
            }        
            return result;
        }
    }

}
</script>

<style scoped>

</style>

