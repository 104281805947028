import axios from "axios";

var backendApi = process.env.VUE_APP_BACKEND_API_URL;

export async function fetchUplaodedImage(file_name) {
    return await axios.get(`${backendApi}/api/speakers/file/upload/${file_name}`);
}

export async function uploadSpeakerImage(data) {
    return await axios.post(`${backendApi}/api/speakers/file/upload`, data);
}